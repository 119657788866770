.collection_row{
    max-width: var(--page-width);
    margin: 0 auto;
    padding: 0 1.5rem;
}

.products_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 25px;
    row-gap: 25px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}
.height_fix{
    display: grid !important;
    grid-template-rows: 50px 1fr !important;
}
@media screen and (max-width: 680px){
    .height_fix{
    	grid-template-rows: 35px 1fr !important;
	}
}
.center_title{
  text-align: center;
}
.product {
    max-width: 190px;
    min-width: 100px;
    width: 100%;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 10px;
}
.card_link{
    text-decoration: none;
}
.product_card_image, .product_card_inner{
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}
.media_hover{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
}
.product_img_row:nth-child(2){
    display: none;
}
.product_img_row:hover ~ .product_img_row:nth-child(2){
    display: block;
}
.product_img_row:nth-child(2):hover{
    display: block;
}
.product_img_row:hover ~ .product_img_row:nth-child(1){
    display: none;
}
.product_img_row{
    position:absolute;
    width: 100%;
    height: 100%;
}
.card-information__wrapper {
    text-align: center;
}
.price {
    flex-direction: column;
}
.status_badge{
    position: absolute;
    bottom: 5px;
    left: 0;
}
.status_sold_out{
    background-color: var(--gan-black);
    color: var(--gan-white);
    padding: 5px;
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
}
.status_sale{
    background-color: var(--gan-green);
    color: var(--gan-white);
    padding: 5px;
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
}
.product_card_info{
    display: grid;
}
.product_card_title{
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
    font-size: var(--ltext);
    color: var(--gan-black);
    padding: 20px 0;
}
.price>dl{
    display: grid;
    grid-template-columns: 1fr;
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
    color: var(--gan-black);
    text-align: center;
}
.price_regular{
    font-size: var(--text);
    color: var(--gan-grey);
}
.product_form_buttons{
    display: flex;
    justify-content: center;
    width: 100%;
  	margin-top: 10px;
}
.form_submit{
    width: 80%;
    background-color: var(--gan-green);
    color: var(--gan-white);
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
    text-align: center;
    outline: none;
    border: none;
    padding: 10px 0;
    text-transform: uppercase;
}
.form_submit:disabled, .form_submit:hover:disabled{
    width: 80%;
    background-color: var(--gan-grey);
    opacity: 0.2;
    color: var(--gan-white);
    font-family: var(--font-body-family);
    font-style: var(--font-body-style);
    font-weight: var(--font-body-weight);
    text-align: center;
    outline: none;
    border: none;
    padding: 10px 0;
    text-transform: uppercase;
    cursor: not-allowed;
}
.form_submit:hover{
    cursor: pointer;
    background-color: var(--gan-green-hover);
}
@media screen and (min-width: 750px){
  .collection_row {
      padding: 0 5rem;
  }
}
@media screen and (max-width: 1050px) {
    .products_wrapper {
        width: 95%;
    }
}